<template lang="pug">
  v-container#inv(fluid='' tag='section' style="padding: 0px;")
    v-dialog(fullscreen="" v-model='dialogSaleNote')
      v-card
        sale-note(@closeModalSale="closeModalSaleNote" :noteToEdit="noteToEdit")
    v-dialog(fullscreen="" v-model='dialogBillingOpenTable')
      v-card
        sale-billing-open-table(@closeModalSale="closeModalSale" :openTableBillig="openTableToEdit")
    v-dialog(v-model='dialogOpenTableClose' width='500')
      v-card
        v-card-title.text-h5.grey.lighten-2
          | Cerra Mesa
        v-card-text
          | Al cerrar la mesa se eliminarán los datos almacenados. ¿Estás seguro de cerrar la mesa seleccionada?
        v-divider
        v-card-actions
          v-spacer
          v-btn(color='green' text='' @click='closeOpenTable()')
            | SI
          v-btn(color='red' text='' @click='dialogOpenTableClose = false')
            | NO
    v-dialog(fullscreen="" v-model='dialogOpenTable')
      v-card
        open-table-modal(@closeModalTable="closeModalTable" :openTableToEdit="openTableToEdit" :tableSecuential="tableSecuential")
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    base-material-card.px-5.py-3(icon='mdi-table' title="Mesas Abiertas" style='min-height: 100vh;')
      v-container#inventory-table(fluid='' tag='section')
        v-row
          v-col(cols="12" md="8" style="padding-top: 0px;")
            v-text-field(v-model="search" append-icon="mdi-search" label= "Buscar" single-line="" hide-details="" clearable)
          v-col(cols="12" md="4")
            v-btn.mr-0(color='primary' rounded='' @click='addNewOpenTable' style="width: 100%;")
              strong
                | NUEVA MESA
      v-skeleton-loader(:loading="cargando" type="table" v-if="firstLoad")
      v-data-table.mx-2#tablaDatos(:headers="headers" v-show="!firstLoad" :items="openTableLst" item-key="uid" :search="search" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" calculate-widths :footer-props="{\'items-per-page-text':'Mesas por página'\}")
        template(v-slot:[`item.status`]='{ item }')
          v-chip(:color="getColor(item.status)" small label) {{ item.status ? item.status : 'ABIERTA' }}
        template(v-slot:item.action="{ item }")
          v-row
            v-col(cols="2" md="2")
              v-tooltip(bottom="")
                template(v-slot:activator="{ on }")
                  v-btn(:disabled="item.status ? item.status === 'CERRADA' : false" text="" icon="" color='brown' v-on="on" @click="editNoteSale(item)")
                    v-icon(small) mdi-clipboard
                span.tooltips Generar nota de venta
            v-col(cols="2" md="2")
              v-tooltip(bottom="")
                template(v-slot:activator="{ on }")
                  v-btn(:disabled="item.status ? item.status === 'CERRADA' : false" text="" icon="" color='green' v-on="on" @click="billingOpenTable(item)")
                    v-icon(small) mdi-send
                span.tooltips Facturar Mesa
            v-col(cols="2" md="2")
              v-tooltip(bottom="")
                template(v-slot:activator="{ on }")
                  v-btn(:disabled="item.status ? item.status === 'CERRADA' : false" text="" icon="" color='blue' v-on="on" @click="editOpenTable(item)")
                    v-icon(small) mdi-eye
                span.tooltips Editar Mesa
            v-col(cols="2" md="2")
              v-tooltip(bottom="")
                template(v-slot:activator="{ on }")
                  v-btn(:disabled="item.status ? item.status === 'CERRADA' : false" text="" icon="" color='orange' v-on="on" @click="printOpenTable(item)")
                    v-icon(small) mdi-printer
                span.tooltips Imprimir Mesa
            v-col(cols="2" md="2")
              v-tooltip(bottom="")
                template(v-slot:activator="{ on }")
                  v-btn(:disabled="item.status ? item.status === 'CERRADA' : false" text="" icon="" color='red' v-on="on" @click="openModalClose(item)" :loading="loadingSave")
                    v-icon(small) mdi-close-circle
                span.tooltips Cerrar Mesa
</template>
<script>
  import * as functions from '../../../functions.js'
  import moment from 'moment/moment'
  export default {
    components: {
      OpenTableModal: () => import('@/views/dashboard/component/application/OpenTableModal'),
      SaleBillingOpenTable: () => import('@/views/dashboard/component/application/SaleBillingOpenTable'),
      SaleNote: () => import('@/views/dashboard/component/application/SaleNote'),
    },
    data: () => ({
      dialogSaleNote: false,
      dialogBillingOpenTable: false,
      dialogOpenTableClose: false,
      selectedOpentable: null,
      loadingSave: false,
      openTableToEdit: {
        secuential: 0,
        number: '',
        observations: '',
        lstProductos: [],
        subtotal: '0.00',
        subcero: '0.00',
        noobjiva: '0.00',
        excentoiva: '0.00',
        totdcto: '0.00',
        ice: '0.00',
        irbpnr: '0.00',
        iva12: '0.00',
        total: '0.00',
        createTime: new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds(),
        createDate: moment(new Date()).format('YYYY-MM-DD'),
        waitress: '',
      },
      noteToEdit: {
        numsecuencial: '',
        client: null,
        fechaEmision: moment(new Date()).format('YYYY-MM-DD'),
        descripcion: '',
        lstProductos: [],
        total: '0.00',
      },
      dialogOpenTable: false,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      search: '',
      cargando: false,
      firstLoad: false,
      ordenarPor: 'status',
      headers: [
        {
          text: 'Mesa',
          value: 'number',
          align: 'left',
          filterable: true,
          width: 100,
        },
        {
          text: 'Observaciones',
          value: 'observations',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Valor Total',
          value: 'total',
          align: 'center',
          filterable: true,
          width: 120,
        },
        {
          text: 'Estado',
          value: 'status',
          align: 'left',
          filterable: true,
          width: 120,
        },
        {
          text: 'ACCIONES',
          align: 'center',
          value: 'action',
          sortable: false,
          width: 160,
        },
      ],
    }),
    computed: {
      companyDataLoad () {
        return this.$store.state.facturacion.companydata
      },
      openTableLst () {
        return this.$store.state.table.openTableLst
      },
      tableSecuential () {
        return this.$store.state.table.tableSecuential
      },
    },
    methods: {
      closeModalSaleNote () {
        this.dialogSaleNote = false
      },
      editNoteSale (item) {
        this.dialogSaleNote = true
        this.noteToEdit = item
        this.noteToEdit.client = {
          idType: {
            codigo: '07',
            texto: 'VENTA A CONSUMIDOR FINAL*',
          },
          address: 'Consumidor Final',
          clientKey: 'consumidorfinal',
          completeData: 'Consumidor Final | 9999999999999',
          lastName: 'Final',
          mail: '',
          businessName: '',
          name: 'Consumidor',
          phone: '',
          cellphone: '0999999999',
          ide: '9999999999999',
          id: 'consumidorfinal',
          viewName: 'Consumidor Final',
        }
        this.noteToEdit.fechaEmision = moment(new Date()).format('YYYY-MM-DD')
        this.noteToEdit.id = null
      },
      billingOpenTable (item) {
        this.openTableToEdit = item
        this.dialogBillingOpenTable = true
      },
      closeModalSale () {
        this.dialogBillingOpenTable = false
      },
      openModalClose (item) {
        this.selectedOpentable = item
        this.dialogOpenTableClose = true
      },
      closeOpenTable () {
        this.selectedOpentable.status = 'CERRADA'
        this.dialogOpenTableClose = false
        this.$store.dispatch('table/closeOpenTable', this.selectedOpentable).then(res => {
          this.loadingSave = false
          if (res) {
            this.loadingSave = false
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Mesa cerrada correctamente',
            }
          } else {
            this.loadingSave = false
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al cerrar la mesa',
            }
          }
        }, reject => {
          this.loadingSave = false
          this.snackbar = {
            show: true,
            color: 'orange',
            text: 'Mesa cerrada con inconvenientes',
          }
        })
      },
      printOpenTable (item) {
        const myWindow = window.open('', 'print-sale', 'fullscreen="yes"')
        item.companyData = this.companyDataLoad
        functions.printDataOpenTable(item, this.$store.state.profile.profileLoaded, myWindow)
      },
      async addNewOpenTable () {
        // para cargar el secuencial de las mesas
        await this.$store.dispatch('table/loadActualSecuential')
        this.dialogOpenTable = true
        this.openTableToEdit = {
          secuential: 0,
          number: '',
          observations: '',
          lstProductos: [],
          subtotal: '0.00',
          subcero: '0.00',
          noobjiva: '0.00',
          excentoiva: '0.00',
          totdcto: '0.00',
          ice: '0.00',
          irbpnr: '0.00',
          iva12: '0.00',
          total: '0.00',
          createTime: new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds(),
          createDate: moment(new Date()).format('YYYY-MM-DD'),
          waitress: '',
        }
      },
      editOpenTable (item) {
        this.dialogOpenTable = true
        this.openTableToEdit = item
      },
      billingTable () {
      },
      getColor (valor) {
        if (!valor || valor === 'ABIERTA') {
          return 'green'
        } else {
          return 'red'
        }
      },
      closeModalTable () {
        this.dialogOpenTable = false
      },
    },
  }
</script>
